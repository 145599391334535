import React from 'react';

class Topic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div>
        <h3>topic: {this.props.match.params.topic.replace('-', ' ')}</h3>
        <pre>{JSON.stringify(this.props.match)}</pre>
      </div>
    )
  }
}

export default Topic;