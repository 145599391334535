import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import Home from './Home';
import About from './About';
import Tech from './Tech';

function App() {
  return (
    <Router>
      <div>
        <ul>
          <li>
            <Link to="/">home</Link>
          </li>
          <li>
            <Link to="/about">about</Link>
          </li>
          <li>
            <Link to="/tech">tech</Link>
          </li>
        </ul>
        <Switch>
          <Route path="/about" render={(props) => <About {...props} />} />
          <Route path="/tech" render={(props) => <Tech {...props} />} />
          <Route path="/" render={(props) => <Home {...props} />} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
