import React from 'react';
import {
  Switch,
  Route,
  Link
} from 'react-router-dom';
import Topic from './Topic';

class Tech extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <h2>tech</h2>

        <ul>
          <li>
            <Link to={`${this.props.match.url}/models`}>
              models
            </Link>
          </li>
          <li>
            <Link to={`${this.props.match.url}/colour-codes`}>
              colour codes
            </Link>
          </li>
        </ul>
        <Switch>
          <Route path={`${this.props.match.path}/:topic`} render={(props) => <Topic {...props} />} />
          <Route path={this.props.match.path}>
            <h3>Please select a tech.</h3>
            <pre>{JSON.stringify(this.props.match)}</pre>
          </Route>
        </Switch>
      </div>
    )
  }
}

export default Tech;