import React from 'react';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <h2>about</h2>
    )
  }
}

export default About;